import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { PublisherComponent } from './videoapp/components/publisher/publisher.component';
import { SubscriberComponent } from './videoapp/components/subscriber/subscriber.component';
import { OpentokService } from './services/opentok.service';
import { VideoAppComponent } from './videoapp/videoapp.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DeviceselectorComponent } from './videoapp/components/deviceselector/deviceselector.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatBadgeModule} from '@angular/material/badge';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import {MatSliderModule} from '@angular/material/slider';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalavisoComponent } from './videoapp/components/modalaviso/modalaviso.component';
import { BoardComponent } from './videoapp/components/board/board.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatSnackBarModule} from '@angular/material/snack-bar';


// Socket config
import { SocketService } from './services/socket.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import config from '../config';
import { SubtitlesComponent } from './videoapp/components/subtitles/subtitles.component';
import { ScreenSharingComponent } from './videoapp/components/screen-sharing/screen-sharing.component';
import { ChatComponent } from './videoapp/components/chat/chat.component';

const socketConfig: SocketIoConfig = {url: config.SOCKET_URL, options: {autoConnect: false}};


const modules = [
  MatDialogModule,
  MatButtonModule,
  MatInputModule,
  MatFormFieldModule,
  MatSelectModule,
  MatBadgeModule,
  DragDropModule,
  MatSliderModule,
  MatMenuModule,
  MatCardModule,
  MatSnackBarModule
];

@NgModule({
imports: [...modules],
exports: [...modules],
declarations: [/* ModalavisoComponent */
  ]
,
})

export class MaterialModule {}

@NgModule({
  declarations: [
    AppComponent,
    VideoAppComponent,
    PublisherComponent,
    SubscriberComponent,
    DeviceselectorComponent,
    ModalavisoComponent,
    BoardComponent,
    SubtitlesComponent,
    ScreenSharingComponent,
    ChatComponent
  ],
  entryComponents: [
    DeviceselectorComponent,
    ModalavisoComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    SocketIoModule.forRoot(socketConfig),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [OpentokService, SocketService],
  bootstrap: [AppComponent]
})
export class AppModule { }
