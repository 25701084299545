<div class="row">
  <div class="row header" id="cabecera-pizarra">
    <ng-container *ngIf="drawAllowed">
    <!-- <div >
      <button (click)="cerrarPizarra()" class="controls-button" title="Ocultar">
        <span class="material-icons">
          chevron_left
        </span>
      </button>
    </div> -->

      <!-- Opciones -->
      <div >
        <button mat-icon-button [matMenuTriggerFor]="opciones" aria-label="Opciones dibujo">
          <mat-icon>more_vert</mat-icon>
        </button>
        <span>Opciones</span>

        <mat-menu #opciones="matMenu">
          <button mat-menu-item (click)="colorSelect.click()">
            <mat-icon [style.color]="colorSelected"> colorize </mat-icon>
            <span>Seleccionar color</span>
          </button>

          <!-- Limpiar pantalla -->
          <button mat-menu-item (click)="opcionesPantalla('clear')">
            <mat-icon>restart_alt</mat-icon>
            <span>Limpiar pantalla</span>
          </button>

          <!-- Deshacer -->
          <button mat-menu-item (click)="opcionesPantalla('undo')" title="Deshacer">
            <mat-icon> skip_previous </mat-icon>
            <span>Deshacer</span>
          </button>

          <!-- Rehacer -->
          <button mat-menu-item (click)="opcionesPantalla('redo')" title="Rehacer">
            <mat-icon> skip_next </mat-icon>
            <span>Rehacer</span>
          </button>

          <!-- Subir imagen -->
          <button mat-menu-item (click)="fileUpload.click()"  [disabled]="socket.userData.role !== 'doctor'">
            <mat-icon>add_a_photo</mat-icon>
            <span>Subir imagen</span>
          </button>

           <!-- Guardar dibujo -->
           <button mat-menu-item (click)="guardarDibujo()" [disabled]="socket.userData.role !== 'doctor'" [attr.disabled]="textoBotonEnviar == 'autorenew' ? true : null" title="Guardar">
            <mat-icon>upload_file</mat-icon>
            <span>Guardar imagen</span>
          </button>

        </mat-menu>


        <input #fileUpload type="file" (change)="fileChangeEvent($event)" accept="image/*" class="inputHidden" />
      </div>

      <!-- Ceder pincel -->
      <div>
        <mat-form-field>
          <mat-label>Ceder pincel</mat-label>
          <mat-select value="_onlyDoctor"  [disabled]="socket.userData.role !== 'doctor'" (openedChange)="getParticipants()" (selectionChange)="setPencil($event)">
            <mat-option value="_onlyDoctor" selected>Solo doctor</mat-option>
            <mat-option *ngFor="let u of users" [value]="u">{{u}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Colors -->
      <div >
        <span>Color:</span>
        <button class="controls-button">
          <input #colorSelect type="color" [(ngModel)]="colorSelected" (change)="establecerColor(colorSelected)" style="opacity: 0; position: fixed;">
          <span class="material-icons" [style.color]="colorSelected">
            colorize
          </span>
        </button>

      </div>

      <!-- Tamaño pincel  -->
      <div class="mt">
        <span>Tamaño del pincel:</span>
        <mat-slider
        thumbLabel
        tickInterval="2"
        step="1"
        min="1"
        max="20"
        aria-label="units"
        [(ngModel)]="pencilSize"
        (change)="establecerLinea(pencilSize)"></mat-slider>
      </div>

    </ng-container>
  </div>

  <div class="row content" id="canvasContainer">
    <div class="col-12">
      <canvas #canvas id="canvas" [ngClass]="{disabled: !drawAllowed}"></canvas>
    </div>
  </div>

</div>


