import { FileUploadService } from './../../../services/file-upload.service';
import { OpentokService } from './../../../services/opentok.service';
import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})

export class ChatComponent implements OnInit, OnChanges {

  @Input() session: OT.Session;
  @Input() chatIsView = false;

  @Output() chatIsViewChange = new EventEmitter<boolean>();
  @Output() unreadMsgEvent = new EventEmitter<number>();

  @ViewChild('scroll') private scroll: ElementRef;
  @ViewChild('file') private inputfile: ElementRef;

  messageData: Object;
  dragOver: Boolean = false;

  messages: any[] = [];
  message: any[] = [];
  file: any;
  textoBotonEnviar = 'attach_file';

  unreadMsg = 0;

  constructor(
    private ots: OpentokService,
    private snackbar: MatSnackBar,
    private uploadFile: FileUploadService
  ) { }


  ngOnInit(): void {
    // On receive a new chat message
    this.session.on('signal', event => {
      if (event.type.endsWith('msgchat')) {
        this.chatWrite(event);
      }

      if (event.type.endsWith('chatMessages')) {
        const data =  JSON.parse(event.data);

        // Set the received messages
        this.messages = data.messages;

        // Emit to parent the unread messages
        this.unreadMsgEvent.emit(this.messages.length);
        this.unreadMsg = this.messages.length;

        // Notify via snackbar to the user
        this.snackbar.open(`Tiene ${this.unreadMsg} mensajes sin leer`, null,
        {duration: 5000, panelClass: 'float-message', horizontalPosition: 'right'});

      }

    });

    // Send all messages to connected user
    this.session.on('connectionCreated', (event) => {
      if (this.messages.length > 0) {
        // Set timeout for the delay in opentok socket
        setTimeout(() => {
          this.session.signal({
            to: event.connection,
            type: 'chatMessages',
            data: JSON.stringify({
              userName: this.ots.sessionData.Session.UserData.name,
              role: this.ots.sessionData.Session.Permissions.TokboxRole,
              messages: this.messages
            })
          }, err => {
            if (err) { console.log(err); }
          });
        }, 5000);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chatIsView.currentValue === true) {
      this.unreadMsg = 0;
      this.unreadMsgEvent.emit(0);
    }
  }

  /**
   * Prepare the message to be sent adding metadata to it
   *
   * @param {ElementRef} textarea - The textarea element to be sent
   * @memberof ChatComponent
   */
  prepareMessage(textarea) {

    const text = textarea.value.trim();

    if (text !== '') {

        this.messageData = {
            type: 'text',
            msg: text,
            username: this.ots.sessionData.Session.UserData.name,
            role: this.ots.sessionData.Session.UserData.role,
            date: moment()
        };

        this.sendMessage('msgchat', false, textarea);
    }

  }

  /**
   * Sends a local message trough openTok socket
   *
   * @param {string} type - Type of message
   * @param {boolean} file - Boolean which indicates if is a file or not
   * @param {*} [textarea=false] - The element textarea itself of false if file
   * @memberof ChatComponent
   */
  sendMessage(type: string, file: boolean, textarea: any = false) {

    this.session.signal({
        type: type,
        data: file ? JSON.stringify(this.file) : JSON.stringify(this.messageData),
    }, err => err && console.error(err));

    if (textarea) { textarea.value = ''; }

    if (file) { this.textoBotonEnviar = 'attach_file'; }

  }

  /**
   * Add a received message to our messages stack
   *
   * @param {*} event - Te received signal on openTok
   * @memberof ChatComponent
   */
  chatWrite(event) {

    const data = JSON.parse(event.data);

    let signedUrl = null;
    let extension = null;
    let name = null;

  //  Si recibimos un fichero, establecemos los datos relativos al mismo
    if (data.type === 'file') {
      signedUrl = data.signedUrl;
      extension = data.extension;
      name = data.name;
      console.log(data);
    }

  //  Guardamos en el array de los mensajes los datos
    this.messages.push({
        msg: data.msg,
        username: data.username,
        type: data.type,
        signedUrl: signedUrl,
        extension: extension,
        name: name,
        date: moment(data.date).format('H:mm'),
        sameUser: data.role === this.ots.sessionData.Session.UserData.role ? true : false
    });


    // Diferenciar si el chat está abierto o no
    if (!this.chatIsView) {
      this.chatDisabled(data);
    } else {
      setTimeout(() => {
        try {
          this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
        } catch (err) { }
      }, 250);
    }

  }

  /**
   * Handle the new messages when the chat is closed
   *
   * @param {*} data
   * @memberof ChatComponent
   */
  chatDisabled(data) {

      let key = null;
      let locale = null;

      // si es fichero, le agrega los datos
      if (data.type === 'file') {
        key = data.file.key;
        locale = data.file.Location;
      }

      const msg = data.type === 'text' ? data.msg : 'Ha enviado un archivo';
      this.snackbar.open(`${data.username}: ${msg}`, null, {duration: 5000, panelClass: 'float-message', horizontalPosition: 'right'});
      this.unreadMsg += 1;
      this.unreadMsgEvent.emit(this.unreadMsg);
      console.log(this.unreadMsg);
  }

  dragOverControl(valor) {

    this.dragOver = valor;

  }

  /**
   * Hide the chat div and emits the status to the parent
   *
   * @memberof ChatComponent
   */
  disableChat() {
    this.chatIsView = false;
    this.chatIsViewChange.emit(false);
  }

  /**
   * Handle the file selected when change the input
   *
   * @param {*} $event - Event on selected a new file
   * @memberof ChatComponent
   */
  public fileEvent($event) {

  //  Si hay fichero seleccionado
    if ($event.target.files[0]) {
  //  Modificamos la variable del listener para añadir el loader al botón
      this.textoBotonEnviar = 'autorenew';

  //  Llamamos al servicio para realizar la petición post
      const estado = this.uploadFile.sendFile($event.target.files[0], this.ots.sessionData.Session.Hash);

  //  Nos suscribimos al servicio y esperamos a la respuesta (interior de la función)
      estado.subscribe(response => {

    //  Procesamos la respuesta obtenida del servidor
        this.processResponse(response);

    //  Limpiamos el fichero del input
        this.inputfile.nativeElement.value = '';

      });

    }

  }

  /**
   * Handle the response after upload file to fileserver
   *
   * @param {Object} response - Object which has the information of the upload file
   * @memberof ChatComponent
   */
  processResponse(response) {

    if (response.resuelto !== undefined && response.resuelto === 'OK') {

      //  Creamos el objeto del fichero
      this.file = {
        type: 'file',
        name: response.nombre_fichero,
        extension: response.extension_fichero,
        signedUrl: response.enlace_firmado,
        username: this.ots.sessionData.Session.UserData.name,
        role: this.ots.sessionData.Session.UserData.role,
        msg: ''
      };

      //  Enviamos el mensaje
      this.sendMessage('msgchat', true);

    } else if (response.resuelto !== undefined && response.resuelto === 'ER') {

      console.log('Error al enviar el mensaje');

    } else {
      console.log('NOKNER');
    }

  }

}
