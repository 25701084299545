import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private _users = new BehaviorSubject([]);
  sharedUsers = this._users.asObservable();
  userData;


  constructor(protected socket: Socket) { }

  /**
   * Se establece conexión al socket y se definen los listeners
   */
  connect(): void {
    this.socket.connect();

    // Reenviamos la información de login
    this.socket.on('relogin', () => {
      if (this.userData) {
        this.login(this.userData);
      }
    });
  }

  /**
   * Desconectamos el socket
   */
  disconnect() {
    this.socket.disconnect();
  }

  /**
   * Enviamos información del usario local al socket así como información de la sala
   * @param data - Objeto con los datos identificativos para el servidor
   * @param data.username - Nombre de usuario
   * @param data.sid - Id de sesión de la sala de Vonage usado para identificar a todos los miembros de una misma sala
   */
  login(data) {
    this.userData = data;
    this.socket.emit('login', JSON.stringify(data));
  }

  /**
   * Manda un mensaje hacia el socket para transmitirlo a todos los usuarios de la sala.
   * @param message - El mensaje transcrito obtenido de AWS Transcribe
   */
  sendMessage(message) {
    this.socket.emit('message', message);
  }

  /**
   * Inicia el dibujo en la pizarra
   *
   * @param {Object} coords - Object with the coords of init point of draw
   * @param {Number} coords.x - Horizontal point coordinate
   * @param {Number} coords.y - Vertical point coordinate
   * @param {String} color - Color of pencil
   * @param {Number} size - Size of Pencil
   * @param {Object} boardSize - Object with the size of your current canvas
   * @memberof SocketService
   */
  drawBegin(coords, color, size, boardSize) {
    this.socket.emit('drawBegin', {coords, color, size, boardSize});
  }

  /**
   * Información sobre el trazo mientras se dibuja
   *
   * @param {Object} coords - Object with the coords of init point of draw
   * @param {Number} coords.x - Horizontal point coordinate
   * @param {Number} coords.y - Vertical point coordinate
   * @param {Object} boardSize - Object with the size of your current canvas
   * @memberof SocketService
   */
  draw(coords, boardSize) {
    this.socket.emit('draw', {coords, boardSize});
  }

  /**
   * Trazo final del dibujo
   *
   * @param {Object} coords - Object with the coords of init point of draw
   * @param {Number} coords.x - Horizontal point coordinate
   * @param {Number} coords.y - Vertical point coordinate
   * @param {Object} boardSize - Object with the size of your current canvas
   * @memberof SocketService
   */
  drawEnd(coords, boardSize) {
    this.socket.emit('drawEnd', {coords, boardSize});
  }

  /**
   * Devuelve el objeto de socket.
   *
   * @readonly
   * @memberof SocketService
   */
  get socketRAW() {
    return this.socket;
  }

  /**
   * Set the value of the users actives based on the OpenTok connection
   *
   * @memberof SocketService
   */
  setUsers(users) {
    this._users.next(users);
  }

}
