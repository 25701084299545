export default {
  // Set this to the base URL of your sample server, such as 'https://your-app-name.herokuapp.com'.
  // Do not include the trailing slash. See the README for more information:
  SAMPLE_SERVER_BASE_URL: 'https://opentok-web-samples-backend.herokuapp.com',
  API_VIDEO_SERVER: 'https://video.api.medicalcenter.io', // 'https://apivideo.portal-medico-mx.com',

  // OR, if you have not set up a web server that runs the learning-opentok-php code,
  // set these values to OpenTok API key, a valid session ID, and a token for the session.
  // For test purposes, you can obtain these from https://tokbox.com/account.
  API_KEY: '',
  SESSION_ID: '',
  TOKEN: '',

  // Socker Server Config
  SOCKET_URL: 'https://sub.video.everhealth.eu:4422',
  SOCKET_URL_DEV: 'http://localhost:4422',

  // AWS Credentials
  AWS_ACCESS_KEY: 'AKIASDLD2ENMRTEF7E3K',
  AWS_SECRET_KEY: '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'

};
