import { Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {

    constructor(private http: HttpClient) { }

    // URL_UPLOAD = "https://everhealth.portal-medico.es/api/videoapp/upload_file";
    URL_UPLOAD = 'https://everhealth.ehealthcenter.io/api/videoapp/upload_file';
    // URL_UPLOAD = 'https://everhealth.medicalcenter.io/api/videoapp/upload_file';

    sendFile(file, hash, tipo = '0') {

        const formData: FormData = new FormData();

        formData.append('file', file, file.name);
        formData.append('hash', hash);
        formData.append('tipo_fichero', tipo);

        return this.http.post(this.URL_UPLOAD, formData);

    }

}
