import { OpentokService } from './../../../services/opentok.service';
import { Component, OnInit, ElementRef, ViewChild, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-screen-sharing',
  templateUrl: './screen-sharing.component.html',
  styleUrls: ['./screen-sharing.component.css']
})
export class ScreenSharingComponent implements OnInit, OnDestroy {

  OT;
  screenCap: boolean;
  publishingScreen = false;
  publisherScreen;

  @ViewChild('screenSharing', { static: true }) screenSharing: ElementRef;
  @Input() sharing = true;
  @Output() sharingChange = new EventEmitter<boolean>();

  constructor(private ots: OpentokService) { }

  ngOnInit(): void {
    this.ots._connected.subscribe(v => {
      console.log({v});
      if (v) {
        this.OT = this.ots.getOT();
        this.checkScreenSharing();
      }
    });
  }

  ngOnDestroy(): void {
    this.publishingScreen = false;
    if (this.publisherScreen) {
      this.publisherScreen.destroy();
    }
  }

  checkScreenSharing() {
    let es = false;
    console.log(es);
    this.OT.checkScreenSharingCapability(response => {
      if (!response.supported || response.extensionRegistered === false) {
        es = false;
      } else if (response.extensionInstalled === false) {
        console.log('necesita extension');
      } else {
        es = true;
      }
    });
    this.screenCap = es;

    if (es) { this.shareScreen(); }
  }

  shareScreen() {
    if (!this.publishingScreen) {

      this.publishingScreen = true;

      this.publisherScreen = OT.initPublisher(this.screenSharing.nativeElement, {
        videoSource: 'screen',
        publishAudio: false,
        insertMode: 'replace',
        fitMode: 'contain',
        style: { buttonDisplayMode: 'off' }
      });

      // Handle stop screen sharing
      this.publisherScreen.on('mediaStopped', event => {
        console.log(event);
        this.publisherScreen.destroy();
        this.sharing = false;
        this.sharingChange.emit(false);
      });

      this.publisherScreen.on('streamDestroyed', event => {
        console.log('streamDestroyed', event);
        this.publisherScreen.destroy();
        this.sharing = false;
        this.sharingChange.emit(false);
      });

      // Publish the screen on OpenTok service
      this.ots._session.publish(this.publisherScreen, (err) => {
        console.log(err);
        if (err.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
          this.publishingScreen = false;
          this.sharing = false;
          this.sharingChange.emit(false);
        }
      });

    } else {
      this.publishingScreen = false;
      this.publisherScreen.destroy();
    }
  }

}
