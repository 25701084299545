<div class="chat-div" *ngIf="chatIsView" cdkDrag>

  <div class="chat-top-bar" cdkDragHandle>
      <button class="close-button" title="Cerrar chat" (click)="disableChat()">
        <i class="material-icons">close</i>
      </button>
  </div>

  <div #scroll class="scroll">
      <mat-card *ngFor="let m of messages" [ngClass]="{'chat-left': !m.sameUser, 'chat-right': m.sameUser}" >
        <mat-card-header>

          <mat-card-title>{{m.username}}</mat-card-title>
          <mat-card-subtitle>

            <!-- File message -->
            <div class="file" *ngIf="m.type === 'file'">
              <span class="material-icons"> file_present </span>
              <span class="link"><a [href]="m.signedUrl" target="_blank">{{m.name}}.{{m.extension}}</a></span>
            </div>

            <!-- Normal message -->
            <span class="message">{{m.msg}}</span>

            <!-- Date -->
            <span class="date">{{m.date}}</span>
          </mat-card-subtitle>
        </mat-card-header>
      </mat-card>
      <!-- <div *ngFor="let m of messages" class="chat"
          [ngClass]="{'chat-left': m.sameUser === false, 'chat-right': m.sameUser === true}">
          <div class="bocadillo">
              <p class="wb author-name">{{m.username}}</p>
              <p class="wb msg-text" style="margin-bottom: 2rem;white-space:pre-wrap;" *ngIf="m.type == 'text'">{{m.msg}}</p>
              <p class="wb msg-text" *ngIf="m.type == 'file'">
                  <a *ngIf="!['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(m.extension); else enlaceDescarga" href='{{m.signedUrl}}' download="{{m.name}}.{{m.extension}}" target="_blank">
                      <span class='material-icons'>attach_file</span>
                      {{m.name}}.{{m.extension}}
                  </a>
                  <ng-template #enlaceDescarga>
                      <img src="{{m.signedUrl}}" class="img-full-width" alt="">
                  </ng-template>
              </p>
          </div>
      </div> -->
  </div>

  <div id="dropzoneElement" class="textarea-div">
    <mat-form-field>
      <mat-label>Escribe tu mensaje</mat-label>
      <button matPrefix mat-icon-button (click)="file.click()">
        <i class="material-icons">file_upload</i>
      </button>
      <textarea #textareaChat (keyup.enter)="prepareMessage(textareaChat)" matInput type="text" autocomplete="off"></textarea>
      <button matSuffix mat-icon-button (click)="prepareMessage(textareaChat)">
        <i class="material-icons">send</i>
      </button>
    </mat-form-field>
  </div>

  <!-- <div id="dropzoneElement" class="textarea-div">

      <button title="Enviar archivo" [attr.disabled]="textoBotonEnviar == 'autorenew' ? true : null"  (dragleave)="dragOverControl(false)" (dragover)="dragOverControl(true)" [ngClass]="{'dragOn': dragOver == true}" class="button-file" (click)="file.click()">

      </button>


      <textarea #textareaChat (ngModel)="textareaChat" (keyup.enter)="prepareMessage(textareaChat)"
          class="textarea"></textarea>
      <button class="button-send" (click)='prepareMessage(textareaChat)' title="Enviar mensaje"><span
              class="material-icons">send</span>
      </button>
  </div> -->

  <!-- <label for="ficheroSubida">
              <span #buttonSendFile [ngClass]="{'spin': textoBotonEnviar == 'autorenew'}"
              class="material-icons">{{textoBotonEnviar}}<input id="ficheroSubida" #file type="file" (change)="fileEvent($event)" /></span>
          </label> -->
        <input id="ficheroSubida" #file type="file" (change)="fileEvent($event)" />
</div>
