<div [ngClass]="{smallVideo: publishingScreen || board}" class="container">
  <div [ngClass]="{'publishing': publishingVideo && !publishingScreen, 'hidden': !streamOriginal}" class="self-video-preview"
      #publisherVideoDiv></div>
  <div [ngClass]="{'publishing': publishingVideo && !publishingScreen, 'hidden': streamOriginal}" class="self-video-preview"
      #publisherCanvasDiv>
      <canvas #canvasFiltro id="canvas-filtro"></canvas>
  </div>

  <!--
  <canvas [ngClass]="{'publishing': publishingVideo && !publishingScreen, 'publishingVideo': publishingVideo && publishingScreen}"
      #publisherVideoDiv></canvas> -->
  <div [ngClass]="{'publishing': !publishingVideo && publishingScreen, 'publishingScreen': publishingVideo && publishingScreen}"
      #publisherScreenDiv></div>

  <!-- Menú izquierdo con opciones -->
  <ng-container *ngIf="publishingVideo">
      <div class="publisher-controls" [ngClass]="{smallVideo:  publishingScreen || board, 'closed': menuClosed}" [class]="role" >
          <button mat-mini-fab class="toogle-open" (click)="menuClosed = !menuClosed">
            <mat-icon class="material-icons">navigate_next</mat-icon>
          </button>

          <!-- Grabación -->
          <button *ngIf="permissions['ManageArchives'] && !ots.recordingSession" title="Comenzar grabación"
              class="controls-button btn-record-archive" (click)="ots.startArchive()">
              <fa-icon [icon]="faCircle"></fa-icon>
          </button>

          <!-- Detener grabación -->
          <button *ngIf="permissions['ManageArchives'] && ots.recordingSession" title="Detener grabación"
              class="controls-button btn-record-archive btn-record-archive-active" (click)="ots.stopArchive()">
              <fa-icon [icon]="faStop"></fa-icon>
          </button>

          <!-- Conmutar estado de la cámara -->
          <button (click)="toggleVideo()" title="Desactivar cámara"
              *ngIf="publisherVideo.stream && publisherVideo.stream.hasVideo; else elseVideo"
              class="controls-button">
              <fa-icon [icon]="faVideo"></fa-icon>
          </button>
          <ng-template #elseVideo>
              <button (click)="toggleVideo()" class="btn-red controls-button " title="Activar cámara">
                  <fa-icon [icon]="faVideoSlash"></fa-icon>
              </button>
          </ng-template>
          <button (click)="toggleAudio()"
              *ngIf="publisherVideo.stream && publisherVideo.stream.hasAudio; else elseAudio" title="Desactivar micrófono"
              class="controls-button">
              <fa-icon [icon]="faMicrophone"> </fa-icon>
          </button>
          <ng-template #elseAudio>
              <button (click)="toggleAudio()" class="controls-button btn-red" title="Activar micrófono">
                  <fa-icon [icon]="faMicrophoneSlash"></fa-icon>
              </button>
          </ng-template>
          <!--
          <button (click)="selectDevices()" class="controls-button">
              <fa-icon [icon]="faCog"></fa-icon>
          </button>
          -->
          <button (click)="alternarStream()" *ngIf="permissions['ManageArchives']" class="controls-button" title="Alternar Stream">
              <span class="material-icons">
                  switch_video
              </span>
          </button>
          <button (click)="hangUp()" class="controls-button btn-red btn-hangup" title="Salir de la llamada">
              <fa-icon [icon]="faPhoneSlash"></fa-icon>
          </button>

          <!-- Botón de subtitulos -->
          <button class="controls-button" (click)="toggleSubtitles()" title="subtitulos" [ngClass]="{active: subtitles}">
            <mat-icon class="material-icons" *ngIf="subtitles">subtitles_off</mat-icon>
            <mat-icon class="material-icons" *ngIf="!subtitles">subtitles</mat-icon>
          </button>

          <!-- Botón de chat -->
          <button (click)="chatView();" [ngClass]="{active: chatIsView}" [matBadge]="unreadMessages" [matBadgeHidden]="unreadMessages < 1" class="controls-button btn-hangup btn-hangup material-icons" title="Chat">
              <mat-icon>chat</mat-icon>
          </button>
          <!-- *ngIf="permissions['ManageArchives']"  -->
          <button (click)="togglePizarra()" [disabled]="publishingScreen" class="controls-button" [ngClass]="{active: board}" title="Pizarra">
              <span class="material-icons">
                  dashboard
              </span>
          </button>
          <!-- screenSharing() -->
          <button (click)="toogleScreenSharing()" [disabled]="board" *ngIf="screenCap" [ngClass]="{active: publishingScreen}" class="controls-button" title="Compartir pantalla">
              <span class="material-icons">
                  cast
              </span>
          </button>

      </div>

      <hr style="display:none">

      <div class="msg">
          <!-- <div *ngIf="message[0] !== undefined">
              <p class="wb msg-autor">{{message[0][1]}}</p>
              <p class="wb msg-text" *ngIf="message[0][3] !== 'file'">{{message[0][0]}}</p>
              <p class="wb msg-text" *ngIf="message[0][3] !== 'text'">
                  <a *ngIf="!permissions['AttachFilesChat']" href='{{message[0][5]}}' target="_blank" (click)="deleteFile(message[0][4])">
                      <span class='material-icons'>attach_file</span>
                      Fichero Adjunto
                  </a>
                  <span *ngIf="permissions['AttachFilesChat']">Fichero enviado</span>
              </p>

          </div> -->

          <div *ngIf="message[0] !== undefined">
              <p class="wb msg-autor">{{message[0].username}}</p>
              <p class="wb msg-text">{{message[0].msg}}</p>
          </div>
      </div>

      <!-- Chat -->
      <app-chat [(chatIsView)]="chatIsView" [session]="session" (unreadMsgEvent)="handleUnreadMsg($event)"></app-chat>

  </ng-container>

</div>

<!-- <app-board *ngIf="pizarraAbierta" [pizarraAbierta]="pizarraAbierta" (callParent)="cerrarPizarra($event)"></app-board> -->
