import { Injectable } from '@angular/core';

import * as CryptoJS from 'crypto-js';
import * as querystring from 'query-string';

@Injectable({
  providedIn: 'root'
})
export class AwsSubtitleService {

  constructor() { }

  // ---- AWS Signature functions ----
  private createCanonicalRequest = function(method, pathname, query, headers, payload) {
    const self = this;
    return [
      method.toUpperCase(),
      pathname,
      this.createCanonicalQueryString(query),
      self.createCanonicalHeaders(headers),
      self.createSignedHeaders(headers),
      payload
    ].join('\n');
  };

  private createCanonicalQueryString(params) {
    return Object.keys(params).sort().map(function(key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');
  }

  private createCanonicalHeaders(headers) {
    return Object.keys(headers).sort().map(function(name) {
      return name.toLowerCase().trim() + ':' + headers[name].toString().trim() + '\n';
    }).join('');
  }

  private createSignedHeaders(headers) {
    return Object.keys(headers).sort().map(function(name) {
      return name.toLowerCase().trim();
    }).join(';');
  }

  private createCredentialScope = function(time, region, service) {
    return [this.toDate(time), region, service, 'aws4_request'].join('/');
  };

  private createStringToSign(time, region, service, request) {
    return [
      'AWS4-HMAC-SHA256',
      this.toTime(time),
      this.createCredentialScope(time, region, service),
      this.hash(request)
    ].join('\n');
  }

  public createSignature(secret, time, region, service, stringToSign) {
    const h1 = this.hmac('AWS4' + secret, this.toDate(time), undefined); // date-key
    const h2 = this.hmac(h1, region, undefined); // region-key
    const h3 = this.hmac(h2, service, undefined); // service-key
    const h4 = this.hmac(h3, 'aws4_request', undefined); // signing-key
    return this.hmac(h4, stringToSign, 'hex');
  }

  private createPresignedS3URL(name, options) {
    options = options || {};
    options.method = options.method || 'GET';
    options.bucket = options.bucket || process.env.AWS_S3_BUCKET;
    return this.createPresignedURL(
      options.method,
      options.bucket + '.s3.amazonaws.com',
      '/' + name,
      's3',
      'UNSIGNED-PAYLOAD',
      options
    );
  }

  public createPresignedURL(method, host, path, service, payload, options) {
    options = options || {};
    options.key = options.key || process.env.AWS_ACCESS_KEY_ID;
    options.secret = options.secret || process.env.AWS_SECRET_ACCESS_KEY;
    options.protocol = options.protocol || 'https';
    options.headers = options.headers || {};
    options.timestamp = options.timestamp || Date.now();
    options.region = options.region || process.env.AWS_REGION || 'us-east-1';
    options.expires = options.expires || 86400; // 24 hours
    options.headers = options.headers || {};

    // host is required
    options.headers.Host = host;

    const query = options.query ? querystring.parse(options.query) : {};
    query['X-Amz-Algorithm'] = 'AWS4-HMAC-SHA256';
    query['X-Amz-Credential'] = options.key + '/' + this.createCredentialScope(options.timestamp, options.region, service);
    query['X-Amz-Date'] = this.toTime(options.timestamp);
    query['X-Amz-Expires'] = options.expires;
    query['X-Amz-SignedHeaders'] = this.createSignedHeaders(options.headers);
    if (options.sessionToken) {
      query['X-Amz-Security-Token'] = options.sessionToken;
    }

    const canonicalRequest = this.createCanonicalRequest(method, path, query, options.headers, payload);
    const stringToSign = this.createStringToSign(options.timestamp, options.region, service, canonicalRequest);
    const signature = this.createSignature(options.secret, options.timestamp, options.region, service, stringToSign);
    query['X-Amz-Signature'] = signature;
    return options.protocol + '://' + host + path + '?' + querystring.stringify(query);
  }

  private hmac(key, string, encoding) {
    const hmac = CryptoJS.HmacSHA256(string, key);
    const output = (encoding) ? hmac.toString(CryptoJS.enc.Hex) : hmac;
    return output;
  }

  private toTime(time) {
    return new Date(time).toISOString().replace(/[:\-]|\.\d{3}/g, '');
  }

  private toDate(time) {
    return this.toTime(time).substring(0, 8);
  }

  private hash(string) {
    const hash = CryptoJS.SHA256(string);
    return hash.toString(CryptoJS.enc.Hex);
  }

  // --- Audio utils ---
  public pcmEncode(input) {
    let offset = 0;
    const buffer = new ArrayBuffer(input.length * 2);
    const view = new DataView(buffer);
    for (let i = 0; i < input.length; i++, offset += 2) {
        const s = Math.max(-1, Math.min(1, input[i]));
        view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
    return buffer;
  }

  public downsampleBuffer(buffer, inputSampleRate = 44100, outputSampleRate = 16000) {
      if (outputSampleRate === inputSampleRate) {
          return buffer;
      }

      const sampleRateRatio = inputSampleRate / outputSampleRate;
      const newLength = Math.round(buffer.length / sampleRateRatio);
      const result = new Float32Array(newLength);
      let offsetResult = 0;
      let offsetBuffer = 0;

      while (offsetResult < result.length) {

          const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);

          let accum = 0,
          count = 0;

          for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++ ) {
              accum += buffer[i];
              count++;
          }

          result[offsetResult] = accum / count;
          offsetResult++;
          offsetBuffer = nextOffsetBuffer;

      }

      return result;

  }

}
