import { SocketService } from './../../../services/socket.service';
import { AwsSubtitleService } from './../../../services/aws-subtitle.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateClient, TranslateTextCommand, TranslateTextCommandInput, TranslateTextCommandOutput } from '@aws-sdk/client-translate';
import * as CryptoJS from 'crypto-js';
import * as Config from '../../../../config';

import { EventStreamMarshaller } from '@aws-sdk/eventstream-marshaller'; // for converting binary event stream messages to and from JSON
import {fromUtf8, toUtf8} from '@aws-sdk/util-utf8-node';


const MicrophoneStream = require('microphone-stream').default; // collect microphone input as a stream of raw bytes
declare var MediaRecorder: any;

@Component({
  selector: 'app-subtitles',
  templateUrl: './subtitles.component.html',
  styleUrls: ['./subtitles.component.css']
})
export class SubtitlesComponent implements OnInit, OnChanges {

  @Input() active: boolean;
  @Input() languageCode = 'es-ES';

  eventStreamMarshaller: any = new EventStreamMarshaller(toUtf8, fromUtf8);
  participants = [];

  // Initial attributes set
  sampleRate = 44100;
  inputSampleRate;
  transcription = '';
  socket;
  micStream;
  socketError = false;
  transcribeException = false;
  recording = false;
  errorMessage = '';
  showContainer = false;

  settings = this.fb.group({
    languageCode: ['es-US', Validators.required],
    region: ['us-east-1', Validators.required]
  });

  // Translator
  client: TranslateClient = new TranslateClient({
      region: 'us-west-2' ,
      credentials: {accessKeyId: Config.default.AWS_ACCESS_KEY , secretAccessKey: Config.default.AWS_SECRET_KEY}
    });

  constructor(private aws: AwsSubtitleService,
              private fb: FormBuilder,
              private EHSocket: SocketService) {

              }

  ngOnInit(): void {
    // Handle socket messages
    this.EHSocket.socketRAW.on('transcription', data => {
      if (this.active) {
        data = JSON.parse(data);

        // Traducir el mensaje si no está en mi idioma
        if (this.languageCode.split('-')[0] === data.lang) {
          this.showMessage(data);
        } else {
          this.translateText(data);
        }
      }


    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active && !changes.active.firstChange) {
      if (changes.active.currentValue) {
        this.startRecording();
      } else {
        this.stopRecording();
      }
    }

    if (changes.languageCode && !changes.languageCode.firstChange) {
      console.log(`CAMBIADO IDIOMA A ${changes.languageCode.currentValue}`);
      this.restart();
    }
  }

  restart() {
    this.stopRecording();
    setTimeout(() => {
      this.startRecording();
    }, 1000);
  }

  showMessage(data) {
    // Obtener la posición del usuario en el array de participantes
    const i = this.participants.map(e => e.userInfo.username).indexOf(data.userInfo.username);
    const lastMessage = new Date().getTime();
    if ( i >= 0 ) {
      this.participants[i].lastMessage = lastMessage;
      this.participants[i].show = true;
      this.participants[i].message = data.message;
    } else {
      data.lastMessage = lastMessage;
      data.show = true;
      this.participants.push(data);
    }
    this.showContainer = (this.participants.filter(e => e.show).length > 0);

    // this.translateText(data.message, 'es', 'en');

    setTimeout(() => {
      const cDate = new Date().getTime();
      this.participants.filter(e => e.lastMessage + 4500 < cDate).forEach(e => e.show = false);
      this.showContainer = (this.participants.filter(e => e.show).length > 0);
    }, 5000);
  }

  // ---- Events functions ----
  /**
   * Inicia la grabación de medios y delega el stream a la función streamAudioToWebSocket
   */
  public startRecording() {
    const self = this;

    // Limpieza del mensaje de error
    this.errorMessage = '';

    // Establecemos el flag de grabación a activo.
    this.recording = true;

    // Se crea una nueva instancia de MicrophoneStream;
    this.micStream = new MicrophoneStream();

    // Llamamos a la API nativa de javascript para obtener audio desde los medios
    navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true
    })
    // Delegamos el stream obtenido y convertimos el stream a binario cuando se resuelva la promesa
    .then(this.streamAudioToWebSocket)
    .catch(function (error) {
        self.errorMessage = `There was an error streaming your audio to Amazon Transcribe. Please try again. <br> ${error}`;
        self.recording = false;
    });
  }

  /**
   * Cierra el socket, para la grabación y cancela los flags de grabación negativos
   */
  stopRecording() {
    this.closeSocket();
    this.micStream.stop();
    this.recording = false;
    this.transcription = '';
  }

  // ---- Functionality ----
/**
 * DEPRECATED:
 * @param userMediaStream - Stream obtenido desde la API navigator.getMedia
 */
  nativeStreamAudioToWebSocket = userMediaStream => {
    const mr = new MediaRecorder(userMediaStream);
    let chunks = [];

    mr.onstart = e => {
      console.log(e.data);
      chunks = [];
    };


    mr.onstop = e => {
        // var blob = new Blob(chunks, { 'type' : 'audio/ogg; codecs=opus' });
        // console.log(blob)
        // this.socket.sendAudio(blob);
      //  socket.emit('radio', blob);
    };


    //  Start recording
    mr.start(4000);

    // Generate the correct Url
    const url = this.createPresignedUrl();
    console.log(url);

    // Init the AWS WebSocket
    this.socket = new WebSocket(url);
    this.socket.binaryType = 'arraybuffer';

    // When get audio from the mic, send it to socket
    this.socket.onopen = () => {
      mr.ondataavailable = rawAudioChunk => {
        console.log(rawAudioChunk);
        // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
        const binary = this.convertAudioToBinaryMessage(rawAudioChunk.data);

        if (this.socket.readyState === this.socket.OPEN) {
          this.socket.send(binary);
        }
      };
    };
  }

  /**
   * Toma el audio desde el stream y lo transmite vía socket a AWS
   * @param userMediaStream - Stream obtenido desde la API navigator.getMedia()
   */
  streamAudioToWebSocket = userMediaStream => {
    // Establecemos el origen del audio a nuestra clase
    this.micStream.setStream(userMediaStream);

    // Obtenemos el SampleRate de la transmisión
    this.micStream.on('format', data => { this.inputSampleRate = data.sampleRate; });

    // Generamos la URL a usar en el websocket de AWS
    const url = this.createPresignedUrl();

    // Inicializamos el WebSocket con la url obtenida anteriormente
    this.socket = new WebSocket(url);
    this.socket.binaryType = 'arraybuffer';

    // Cuando se establezca conexión con el socket, la pasamos vía socket
    this.socket.onopen = () => {
      this.micStream.on('data', rawAudioChunk => {
          // Convertimos el stream de un RAW de bytes de audio, Transcribe espera obtener PCM con metadatos adicionales
          const binary = this.convertAudioToBinaryMessage(rawAudioChunk);

          // Si el socket está abierto mandamos la información en binario a través del mismo
          if (this.socket.readyState === this.socket.OPEN) {
            this.socket.send(binary);
          }
      });

    };

    this.wireSocketEvents();

  }

  /**
   * Convierte el audio en RAW a binario añadiendo cabeceras necesarias para Transcribe
   * @param audioChunk - Audio en RAW
   * @returns binary
   */
  convertAudioToBinaryMessage = audioChunk => {
    // Pasamos el stream a RAW
    const raw = MicrophoneStream.toRaw(audioChunk);

    if (raw === null) {
      return;
    }

    // Convertimos el audio desde RAW de bytes a PCM (Pulse-Code Modulation), Modulación por impulsos codificados
    const downsampledBuffer = this.aws.downsampleBuffer(raw, this.inputSampleRate, this.sampleRate);
    const pcmEncodedBuffer = this.aws.pcmEncode(downsampledBuffer);

    // Añadimos las cabeceras en JSON y estructuramos el mensaje
    const audioEventMessage = this.getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

    // Convertimos el objeto JSON y los headers a un evento de mensaje en stream binario
    const binary = this.eventStreamMarshaller.marshall(audioEventMessage);

    return binary;
  }

  /**
   * Esucha de los eventos de socket de AWS
   */
  wireSocketEvents = () => {
    // Evento de nuevo mensaje recibido
    this.socket.onmessage = message => {
        // Convertimos el evento de stream binario a JSON
        const messageWrapper = this.eventStreamMarshaller.unmarshall(Buffer.from(message.data));
        const messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));
        if (messageWrapper.headers[':message-type'].value === 'event') {
            // Delegamos las acciones del mensaje recibido a otra función
            this.handleEventStreamMessage(messageBody);
        } else {
            // Establecemos la Excepción a true y guardamos el mensaje de error
            this.transcribeException = true;
            this.errorMessage = messageBody.Message;
            this.recording = false;
        }
    };

    // Evento de error
    this.socket.onerror = () => {
        this.socketError = true;
        this.errorMessage = 'WebSocket connection error. Try again.';
        this.recording = false;
    };

    // Evento al cerrar el socket
    this.socket.onclose = closeEvent => {
        // paramos la grabación de audio
        this.micStream.stop();

        // A partir de evento de error mostramos información sobre la razón del cierre
        if (! this.socketError && !this.transcribeException) {
            if (closeEvent.code !== 1000) {
                this.errorMessage = `Streaming Exception<br>${closeEvent.reason}`;
            }
            this.recording = false;
        }
    };
  }

  /**
   * Realiza una acción sobre un mensaje recibido desde AWS Transcribe
   * @param {Object} messageJson - JSON con la información del evento de mensaje
   */
  handleEventStreamMessage(messageJson) {
      const results = messageJson.Transcript.Results;

      if (results.length > 0) {
          if (results[0].Alternatives.length > 0) {
              let transcript = results[0].Alternatives[0].Transcript;

              // fix encoding for accented characters
              transcript = decodeURIComponent(escape(transcript));


              // update the textarea with the latest result
              // this.transcription = transcript;
              this.EHSocket.sendMessage({message: transcript, lang: this.languageCode.split('-')[0]});

              // if this transcript segment is final, add it to the overall transcription
              // if (!results[0].IsPartial) {
              //     // scroll the textarea down
              //     // $('#transcript').scrollTop($('#transcript')[0].scrollHeight);

              //     this.transcription += transcript + '\n';
              // }
          }
      }
  }

  /**
   * Cierra el socket de AWS para que deje de transcribir
   */
  closeSocket() {
      if (this.socket.readyState === this.socket.OPEN) {
          // Se cierra el micro para que deje de obtener información desde el stream
          this.micStream.stop();

          // Se envía un frame vacío para que Transcribe inice el cierre del WebSocket tras enviar todas las transcripciones
          const emptyMessage = this.getAudioEventMessage(Buffer.from([]));
          const emptyBuffer = this.eventStreamMarshaller.marshall(emptyMessage);
          this.socket.send(emptyBuffer);
      }
  }


  /**
   * Encapsula los datos de audio en JSON
   * @param buffer - buffer de datos obtenidos por AWS
   * @returns JSON
   */
  getAudioEventMessage(buffer) {
    return {
        headers: {
            ':message-type': {
                type: 'string',
                value: 'event'
            },
            ':event-type': {
                type: 'string',
                value: 'AudioEvent'
            }
        },
        body: buffer
    };
  }

  /**
   * Devuelve la URL de conexión preautenticada del socket
   * @returns {string}
   */
  createPresignedUrl() {
    const endpoint = `transcribestreaming.${this.settings.value.region}.amazonaws.com:8443`;

    // Generamos la URL
    return this.aws.createPresignedURL(
        'GET',
        endpoint,
        '/stream-transcription-websocket',
        'transcribe',
        CryptoJS.SHA256('').toString(CryptoJS.enc.Hex),
        {
            'key': Config.default.AWS_ACCESS_KEY,
            'secret': Config.default.AWS_SECRET_KEY,
            'sessionToken': '',
            'protocol': 'wss',
            'expires': 15,
            'region': this.settings.value.region,
            'query': `language-code=${this.languageCode}&media-encoding=pcm&sample-rate=${this.sampleRate}`
        }
    );
  }

  translateText(data) {

    const params: TranslateTextCommandInput = {
      SourceLanguageCode: data.lang,
      TargetLanguageCode: this.languageCode.split('-')[0],
      Text: data.message
    };

    const command = new TranslateTextCommand(params);

    this.client.send(command).then(result => {
      data.message = result.TranslatedText;
      data.lang = result.TargetLanguageCode;
      this.showMessage(data);
    });


  }

}
