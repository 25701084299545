
import { SocketService } from './../../../services/socket.service';
import { Component, ElementRef, AfterViewInit, ViewChild, Input, OnDestroy, Output, EventEmitter, OnChanges } from '@angular/core';
import { OpentokService } from '../../../services/opentok.service';
import { FileUploadService } from '../../../services/file-upload.service';
import { faVideo, faSyncAlt, faVideoSlash, faMicrophone, faMicrophoneSlash, faPhoneSlash, faCircle, faStop, faCog } from '@fortawesome/free-solid-svg-icons';


declare var DeepAR: any;
declare var MediaRecorder: any;


@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.css']
})

export class PublisherComponent implements AfterViewInit, OnDestroy, OnChanges {

  @ViewChild('publisherVideoDiv', { static: true }) publisherVideoDiv: ElementRef;
  @ViewChild('publisherCanvasDiv', { static: true }) publisherCanvasDiv: ElementRef;
  @ViewChild('publisherScreenDiv', { static: true }) publisherScreenDiv: ElementRef;
  @ViewChild('buttonSendFile') buttonFile: ElementRef;
  @ViewChild('.self-video-preview canvas', {static: true}) publisherVideoCanvasPreview: ElementRef;
  @ViewChild('canvasFiltro', {static: true}) deepARCanvasRef: ElementRef;
  @Input() session: OT.Session;
  @Input() subtitles: boolean;
  @Input() smallVideo = false;
  @Input() board = false;
  @Input() role = '';
  @Input() publishingScreen = false;


  @Output() subtitlesChange = new EventEmitter<boolean>();
  @Output() boardChange = new EventEmitter<boolean>();
  @Output() smvideoChange = new EventEmitter<Object>();

  deepARCanvas: any;


// video chat
  publisherVideo: OT.Publisher;
  publishingVideo: Boolean;

// screen chat
  publisherScreen: OT.Publisher;
  screenCap: Boolean;

//  muestra los iconos
  faVideo = faVideo;
  faVideoSlash = faVideoSlash;
  faMicrophone = faMicrophone;
  faMicrophoneSlash = faMicrophoneSlash;
  faPhoneSlash = faPhoneSlash;
  faCircle = faCircle;
  faSyncAlt = faSyncAlt;
  faStop = faStop;
  faCog = faCog;
  messageData: object;

// chat
  messages: any[] = [];
  message: any[] = [];
  @ViewChild('scroll') private scroll: ElementRef;
  chatIsView = false;
  file: any;
  @ViewChild('file') private inputfile: ElementRef;
  textoBotonEnviar = 'attach_file';
  unreadMessages = 0;

//  Dropzone
  dragOver: Boolean = false;
  menuClosed = false;

//  DeepAR
// deepARCanvas: any = document.createElement('canvas');
  canvasContext: any;
  mediaStream: any;
  videoTracks: any;

//  DeepAR control
  originalBackground = true; // true;

  copiaStream: any;
  primeraVez = 1;

  deepar: any;
  streamOriginal: Boolean = true;
  deepArIniciado: Boolean = false;


  // Permissions
  permissions = {'ManageArchives': false, 'AttachFilesChat': false};

// constructor
  constructor(public ots: OpentokService,
              public uploadFile: FileUploadService,
              private socket: SocketService,
              ) {
    this.publishingVideo = false;
    this.publishingScreen = false;
    this.screenCap = false;

  }

  ngOnInit() {

  }

  ngOnChanges() {

  }

// al inicio
  ngAfterViewInit() {
    const self = this;
    this.deepARCanvas = document.getElementById('canvas-filtro');
    this.canvasContext = this.deepARCanvas.getContext('webgl');
    this.mediaStream = this.deepARCanvas.captureStream(25);
    this.videoTracks = this.mediaStream.getVideoTracks();
// document.body.appendChild(this.deepARCanvas);
//  start DeepAR

// this.startDeepAR();
    const OT = this.ots.getOT();

// inicia el video chat
// tslint:disable-next-line:max-line-length
// this.publisherVideo = OT.initPublisher(this.publisherVideoDiv.nativeElement, { insertMode: 'append', fitMode: 'contain', style: { buttonDisplayMode: 'off' } });
// tslint:disable-next-line:max-line-length
// this.publisherVideo = OT.initPublisher(this.deepARCanvas.nativeElement, { insertMode: 'append', videoSource: this.videoTracks[0], fitMode: 'contain', style: { buttonDisplayMode: 'off' } });
    this.backgroundControl();

// si se ha conectado (entiendo) inicia la conexion con la api para enviar mensajes
    /* if (this.session) {
      if (this.session['isConnected']()) {
        this.publish();
      }
      this.session.on('sessionConnected', () => this.publish());
    } */

// envia señal de que el usuario no ha dado permisos para la camara

//  if (!ots.hasPermission('ManageStreams'))
    if (this.ots.userConfig.MustSendSignalIfPermissionsDenied) {
      this.publisherVideo.on('accessDenied', () => {
        setTimeout(() => {
          this.session.signal({
            type: 'permissionsDenied',
//  to: event.from, como no conocemos el stram del médico lo enviamos a todos
            data: this.publisherVideo.id + ''
          }, err => err && console.error(err));
        }, 5000);

      });
    }

    // Get the permissions
    Object.keys(this.permissions).forEach(i => self.permissions[i] = self.ots.hasPermission(i));

    // comprueba si se puede compartir pantalla
    let es = false;
    OT.checkScreenSharingCapability(function (response) {
      if (!response.supported || response.extensionRegistered === false) {
        es = false;
      } else if (response.extensionInstalled === false) {
        console.log('necesita extension');
      } else {
        es = true;
      }
    });
    this.screenCap = es;


    this.socketFunctions();

    // Delete div
    const elementoSobrante = document.querySelector('body>.OT_root.OT_publisher');
    if (elementoSobrante) { elementoSobrante.remove(); }
  }

// cuando destruyes la sesion, cierra las conexiones
  ngOnDestroy(): void {
    this.session.unpublish(this.publisherVideo);
    this.session.unpublish(this.publisherScreen);
  }

// cuando ya ha conectado el chat de video
  publish() {
    this.session.publish(this.publisherVideo, (err) => {
      if (err && err.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
// alert(JSON.stringify(err));
        alert('Por favor, revise los permisos de audio, vídeo y ubicación para poder realizar la videoconsulta, ¡gracias!');
      } else if  (err && err.name === 'OT_TIMEOUT') {
        alert('No se ha podido conectar debido a una mala calidad de conexión');
      } else {
        this.publishingVideo = true;

        this.selectDevices();

        this.publisherVideo.publishVideo(this.ots.userConfig.AutostartPublisherVideo);
        this.publisherVideo.publishAudio(this.ots.userConfig.AutostartPublisherAudio);

        this.session.on('signal', event => {
          if (event.type.endsWith('permissionsDenied')) {

//  TODO: handle permissions message
            if (this.ots.hasPermission('ManageStreams')) {//  es el medico

              alert('El usuario no ha aceptado todos los permisos de su dispositivo, comuníqueselo para poder realizar la videoconsulta');
            }
          }

          if (event.type.endsWith('videoDeshabilitadoPaciente')) {

            alert('Se ha perdido la conexión debido a la baja calidad en la red, por favor, intente conectarse de nuevo');
          }

          if (event.type.endsWith('fetch_deviceList')) {
            OT.getDevices((error, devices) => {
              if (!error) {
                this.session.signal({
                  type: 'fetch_deviceListResponse',
                  to: event.from,
                  data: JSON.stringify(this.generateDevicesData(devices))
                // tslint:disable-next-line:no-shadowed-variable
                }, err => error && console.error(err));
              }
            });
          }
          if (event.type.endsWith('fetch_deviceListResponse')) {
            /* DeviceselectorComponent.Open(this.matDialog, JSON.parse(event.data), res=>{
              this.session.signal({
                type: 'moderate_setDevices',
                to: event.from,
                data: JSON.stringify(res)
              }, err=>err&&console.error(err));
            }); */
          }

// acciones del moderador para activar/desctivar cosas
          if (event.type.endsWith('moderate_setDevices')) {
            this.setDevices(JSON.parse(event.data));
          }
          if (event.type.endsWith('moderate_cycleVideoStream')) {
            this.cycleVideo();
          }
          if (event.type.endsWith('moderate_cycleAudioStream')) {
            this.cycleAudio();
          }
          if (event.type.endsWith('moderate_toggleAudioStream')) {
            this.toggleAudio();
          }
          if (event.type.endsWith('moderate_startStetho')) {
            (<any>window).location.href = 'stethoscope:slave?room=' + this.ots.sessionData.Session.Id;
          }

//  Imagen Pizarra
          if (event.type.endsWith('imagenPizarra')) {
            console.log(event);
          }
        });

        if (this.publisherVideo.stream) {
          this.copiaStream = this.publisherVideo.stream;
        }
      }
    });
  }

  socketFunctions() {
    // Socket config
    this.socket.connect();

    const userInfo = {
      'sid': this.ots.session.sessionId,
      'username': this.ots.userData.name,
      'role': this.ots.userData.role,
      'hash': this.ots.sessionData.Session.Hash
    };

    this.socket.login(userInfo);

    this.socket.socketRAW.on('boardStatus', data => {
      if (data.EH.username !== this.ots.userData.name) {
        this.board = data.status;

        // Emit changes
        if (this.publishingScreen) { this.publishingScreen = false; }
        this.smvideoChange.emit({board: this.board, subtitles: this.subtitles, publishingScreen: this.publishingScreen});

      }
    });
  }

// activa/desactiva audio
  toggleAudio() {
    this.publisherVideo.publishAudio(!this.publisherVideo.stream.hasAudio);
  }

// activa/desactiva video
  toggleVideo() {
    if (!this.publisherVideo.stream) {
      this.publisherVideo.stream = this.copiaStream;
    }
    this.publisherVideo.publishVideo(!this.publisherVideo.stream.hasVideo);
  }

// cambia opciones del video
  cycleVideo = () => this.publisherVideo.cycleVideo();

// cambia opciones del audio
  cycleAudio() {
//  Cycling through microphone inputs
    OT.getDevices((err, devices) => {
      if (!err) {
        let currentIndex = 0;
        const audioInputs = devices.filter( dev => dev.kind === 'audioInput');
//  Find the right starting index for cycleMicrophone
        audioInputs.forEach((dev, idx) => {
          if (dev.label === this.publisherVideo.getAudioSource().label) {
            currentIndex = idx;
          }
        });

        const device = audioInputs[(currentIndex + 1) % audioInputs.length];
        this.publisherVideo.setAudioSource(device.deviceId);
        console.log(`Changed audio source [${(currentIndex + 1)}/${audioInputs.length}]`, device.label, device.deviceId);
      } else {
        console.error(err);
      }
    });

  }

  backgroundControl() {

    console.log('el media', this.mediaStream.getVideoTracks());

    if (this.originalBackground) {
      this.activeOriginalBackground();
    } else {
      this.activeAlternativeBackground();
    }

    if (this.primeraVez === 1) {
      this.primeraVez--;
      this.startDeepAR();
    }
  }

  activeOriginalBackground() {

    if (this.session && this.primeraVez === 1) {
      if (this.session['isConnected']()) {
        this.publish();
      }
      this.session.on('sessionConnected', () => this.publish());
    }

    if (this.primeraVez) {
//  Realizamos la publicación del Stream de vídeo
      this.publisherVideo = OT.initPublisher(this.publisherVideoDiv.nativeElement,
        { insertMode: 'append',
          fitMode: 'contain',
          style: { buttonDisplayMode: 'off' }
        }
      );

      this.session.publish(this.publisherVideo, (err) => {

      });
//  Mostramos el vídeo normal
      this.publisherScreenDiv.nativeElement.setAttribute('style', 'display: block');
    } else {

      this.publisherVideo = OT.initPublisher(this.publisherVideoDiv.nativeElement, {
        insertMode: 'append',
        fitMode: 'contain',
        style: { buttonDisplayMode: 'off' }
      });

      this.session.publish(this.publisherVideo, (err) => {

      });
    }


  }

  activeAlternativeBackground() {

//  Primera vez
    if (!this.deepArIniciado) {

      this.publisherVideo = OT.initPublisher(this.publisherCanvasDiv.nativeElement, {
         insertMode: 'replace',
         videoSource: this.videoTracks[0],
         fitMode: 'contain',
         style: { buttonDisplayMode: 'off' }
        }
      );

      this.session.publish(this.publisherVideo, (err) => {
      });

      this.deepArIniciado = true;
    } else {
    //  Resto
    //  Aquí por algún motivo deja de publicar

      this.mediaStream = this.deepARCanvas.captureStream(25);
      this.videoTracks = this.mediaStream.getVideoTracks();
      console.log('cosas', {
        insertMode: 'replace',
        videoSource: this.videoTracks[0],
        fitMode: 'contain',
        style: { buttonDisplayMode: 'off' }
      });
//  EL FALLO ES QUE LA VARIABLE DE ARRIBA ESTÁ UNDEFINDED
      this.publisherVideo = OT.initPublisher(this.publisherCanvasDiv.nativeElement, {
        insertMode: 'replace',
        videoSource: this.videoTracks[0],
        fitMode: 'contain',
        style: { buttonDisplayMode: 'off' }
      });
      this.session.publish(this.publisherVideo, (err) => {
        console.log(err);

      });

    }
    const elementoSobrante = document.querySelector('body>.OT_root.OT_publisher');
    if (elementoSobrante) { elementoSobrante.remove(); }
  }

  generateDevicesData(devices) {
    return {
      inputs: {
        audio: devices.filter((device) => device.kind === 'audioInput'),
        video: devices.filter((device) => device.kind === 'videoInput')
      },
      actual: {
        audio: null,
        video: null
      }
    };
  }

  selectDevices() {
    OT.getDevices((err, devices) => {
      if (!err) {
// DeviceselectorComponent.Open(this.matDialog, this.generateDevicesData(devices), res=>this.setDevices(res));
      } else {
        console.error(err);
      }
    });
  }

// cambia el dispositivo
  setDevices(res) {
    if (res) {
      console.log('Changing devices', res);
      this.publisherVideo.setAudioSource(res.audio);
      this.setVideoSource(res.video);
    }
  }

// cambia la entrada de video
  async setVideoSource(video: string) {
    let lastDevice = '';
    for (let i = 0; i < 10 && lastDevice !== video; i++) {
      lastDevice = (await this.publisherVideo.cycleVideo()).deviceId;
    }
  }

// cierra sesion
  hangUp() {

    const sess = this.session;
    this.session.on('sessionDisconnected', function (event) {
      console.log(event);
      if (window.confirm('Do you really want to leave?')) {
        window.open('about:blank', '_self').close();
      }
    });
    this.session.disconnect();

  }

  screenSharing() {

    if (!this.publishingScreen) {

      this.publishingScreen = true;

      this.publisherScreen = OT.initPublisher(this.publisherScreenDiv.nativeElement, {
        videoSource: 'screen',
        publishAudio: false,
        insertMode: 'replace',
        fitMode: 'contain',
        style: { buttonDisplayMode: 'off' }
      });

      this.session.publish(this.publisherScreen, (err) => {
        if (err.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
          this.publishingScreen = false;
        }
      });

    } else {
      this.publishingScreen = false;
      this.publisherScreen.destroy();
    }
  }


//  activa/desactiva el chat
  chatView() {
    this.chatIsView = !this.chatIsView;
  }

//  almacena el fichero en la variable file
  public fileEvent($event) {

//  Si hay fichero seleccionado
    if ($event.target.files[0]) {

//  Modificamos la variable del listener para añadir el loader al botón
      this.textoBotonEnviar = 'autorenew';

//  Llamamos al servicio para realizar la petición post
      const estado = this.uploadFile.sendFile($event.target.files[0], this.ots.sessionData.Session.Hash);

//  Nos suscribimos al servicio y esperamos a la respuesta (interior de la función)
      estado.subscribe(response => {

//  Procesamos la respuesta obtenida del servidor
        this.processResponse(response);

//  Limpiamos el fichero del input
        this.inputfile.nativeElement.value = '';

      });

    }

  }

  processResponse(response) {

    if (response.resuelto !== undefined && response.resuelto === 'OK') {

//  Creamos el objeto del fichero
      this.file = {
        type: 'file',
        name: response.nombre_fichero,
        extension: response.extension_fichero,
        signedUrl: response.enlace_firmado,
        username: this.ots.sessionData.Session.UserData.name,
        role: this.ots.sessionData.Session.UserData.role,
        msg: ''
      };

//  Enviamos el mensaje
      this.sendMessage('msgchat', true);

    } else if (response.resuelto !== undefined && response.resuelto === 'ER') {

      console.log('Error al enviar el mensaje');

    } else {
      console.log('NOKNER');
    }

  }

  getFileType() {

    let type = this.file.data.split(';');
    type = type[0].split('/')[1];

    if (type !== undefined) {
      return type;
    } else {
      return '';
    }

  }

// sube el archivo a aws
  async uploadfile() {

    /*// si no hay fichero
    if (this.file === null) {
      return;
    }

    try {
      var s3 = new AWS.S3({ apiVersion: '2006-03-01' });
      var params = { Bucket: this.BUCKETAWS, Key: Date.now() + this.file.name, Body: this.file, ACL: 'public-read', };
      const data = await s3.upload(params).promise();

// Ya esta subido, lo borro
      this.file = null;
      this.inputfile.nativeElement.value="";

// ahora envio los datos al chat
      if (data.Location !== null) {
        this.session.signal({
          type: 'msgchat',
          data: JSON.stringify({
            type: 'file',
            msg: "",
            file: data,
            username: this.ots.sessionData.Session.UserData.name,
            role: this.ots.sessionData.Session.UserData.role
          })
        }, err => err && console.error(err));
      }
    }
// en caso de error
    catch (err) {
      console.log(err);
    } */

  }

  getRol() {
    return this.ots.sessionData.Session.UserData.role;
  }


// borra el fichero (este metodo se usa cuando el usuario le da a descargar)
  /* async deleteFile(file) {
    try {
      var s3 = new AWS.S3({ apiVersion: '2006-03-01' });
      var params = { Bucket: this.BUCKETAWS, Key: file };
      const data = await s3.deleteObject(params).promise();
    }
    catch (err) {
      console.log(err);
    }
  } */

  prepareMessage(textarea) {

    const text = textarea.value.trim();

    if (text !== '') {

        this.messageData = {
            type: 'text',
            msg: text,
            username: this.ots.sessionData.Session.UserData.name,
            role: this.ots.sessionData.Session.UserData.role
        };

        this.sendMessage('msgchat', false, textarea);
    }

  }

// boton de enviar el mensaje
  sendMessageOld(textarea = false, sendfile: boolean) {

    /* if (sendfile) {
      this.uploadfile();
    }

    let text = textarea.value.trim();
    text = text.slice(0, 288);

    if (textarea.value.trim() !== '') {
      this.session.signal({
        type: 'msgchat',
        data: JSON.stringify({
          type: 'text',
          msg: text,
          username: this.ots.sessionData.Session.UserData.name,
          role: this.ots.sessionData.Session.UserData.role
        })
      }, err => err && console.error(err));
    }
    textarea.value = ''; */
  }

    sendMessage(type: string, file: boolean, textarea: any = false) {

        this.session.signal({
            type: type,
            data: file ? JSON.stringify(this.file) : JSON.stringify(this.messageData),
        }, err => err && console.error(err));

        if (textarea) { textarea.value = ''; }

        if (file) { this.textoBotonEnviar = 'attach_file'; }

    }

    dragOverControl(valor) {

      this.dragOver = valor;

    }

    startDeepAR() {

      const deepAR = DeepAR({
        canvasWidth: 640,
        canvasHeight: 480,
        licenseKey: this.ots.sessionData.Session.ApiKeyDeepAR,
        libPath: '../../../../assets/deepar',
        segmentationInfoZip: '../../../../assets/deepar/segmentation.zip',
        canvas: this.deepARCanvas,
        numberOfFaces: 1,
        onInitialize: function() {
//  start video immediately after the initalization, mirror = true
          deepAR.startVideo(true);

          deepAR.switchEffect(0, 'slot', '../../../../assets/deepar/effects/background_segmentation/background_segmentation', function() {
//  effect loaded
          });
        }
      });

// this.publisherVideoDiv.nativeElement.;
      this.publisherCanvasDiv.nativeElement.appendChild(this.deepARCanvas);
      this.publisherCanvasDiv.nativeElement.setAttribute('style', 'display: block');

      this.deepARCanvasRef.nativeElement.setAttribute('style', 'display: none');
      deepAR.downloadFaceTrackingModel('../../../../assets/deepar/models-68-extreme.bin');

      /* var filterIndex = 0;
      var filters = [
        './effects/lion',
        './effects/flowers',
        './effects/dalmatian',
        './effects/background_segmentation',
        './effects/background_blur',
        './effects/aviators'
      ];
      var changeFilterButton = document.getElementById('change-filter-button'); */
      /* changeFilterButton.onclick = function() {
        filterIndex = (filterIndex + 1) % filters.length;
        deepAR.switchEffect(0, 'slot', filters[filterIndex]);
      } */


//  Because we have to use a canvas to render to and then stream to the
//  Vonage publisher, changing tabs has to pause the video streaming otherwise it will cause a crash
//  by pausing the 'window.requestAnimationFrame', more can be seen in the documentation:
//  https:// developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
      let visible = true;
      document.addEventListener('visibilitychange', function (event) {
        visible = !visible;
//  pause and resume are not required, but it will pause the calls to 'window.requestAnimationFrame'
//  and the entire rendering loop, which should improve general performance and battery life
        if (!visible) {
          deepAR.pause();
          deepAR.stopVideo();
        } else {
          deepAR.resume();
          deepAR.startVideo(true);
        }
      });

      this.deepar = deepAR;

    }

    togglePizarra() {
      this.board = !this.board;

      if (this.publishingScreen) { this.publishingScreen = false; }
      this.smvideoChange.emit({board: this.board, subtitles: this.subtitles, publishingScreen: this.publishingScreen});


      this.socket.socketRAW.emit('changeBoardStatus', this.board);
    }

    alternarStream() {

      this.originalBackground = !this.originalBackground;
      this.streamOriginal = !this.streamOriginal;
      console.log('streamOriginal', this.streamOriginal);
      this.publisherVideo.destroy();
      this.backgroundControl();
    }

    /**
     *Toogle the value of the subtitles boolean
     *
     * @memberof PublisherComponent
     */
    toggleSubtitles() {
      this.subtitles = !this.subtitles;
      this.smvideoChange.emit({board: this.board, subtitles: this.subtitles, publishingScreen: this.publishingScreen});

    }

    toogleScreenSharing() {
      this.publishingScreen = !this.publishingScreen;
      if (this.board) { this.board = false; }

      this.smvideoChange.emit({board: this.board, subtitles: this.subtitles, publishingScreen: this.publishingScreen});

    }

    handleUnreadMsg(n) {
      this.unreadMessages = n;
    }
}

