<div [ngClass]="{'smallVideo': mode === 'smallVideo'}" class="container">
  <div #subscriberDiv></div>

  <div class="tag" *ngIf="mode === 'smallVideo'">
    {{name}}
  </div>

  <div class="subscriber-controls">

      <ng-container *ngIf="permissions['ManageStreams']">
          <button class="controls-button" (click)="cycleParticipantVideo()" title="Cambiar entrada de vídeo del paciente"><fa-icon [icon]="faSyncAlt"></fa-icon> <fa-icon [icon]="faVideo"></fa-icon></button>
          <button class="controls-button" (click)="cycleParticipantAudio()" title="Cambiar entrada de audio del paciente"><fa-icon [icon]="faSyncAlt"></fa-icon> <fa-icon [icon]="faMicrophone"></fa-icon></button>

          <button *ngIf="permissions['StartStethoscope']" (click)="startStetho()" class="controls-button" title="Estetoscopio"><fa-icon [icon]="faStethoscope"></fa-icon></button>

          <button *ngIf="takingSnapshot === false; else takingSnapshotElement" (click)="captureSnapshot()" class="controls-button" title="Captura de pantalla"><fa-icon [icon]="faCamera"></fa-icon></button>
          <ng-template #takingSnapshotElement>
              <button disabled class="controls-button spin"><fa-icon class="spin" [icon]="faSpinner"></fa-icon></button>
          </ng-template>

          <button (click)="toggleAudio()" *ngIf="subscriber && subscriber.stream && subscriber.stream.hasAudio; else elseAudio" title="Silenciar usuario" class="mute controls-button required">
              <fa-icon [icon]="faMicrophone"></fa-icon>
          </button>
          <ng-template #elseAudio>
              <button (click)="toggleAudio()" class="controls-button btn-red mute required"><fa-icon [icon]="faMicrophoneSlash" title="Desinlenciar usario"></fa-icon></button>
          </ng-template>

          <!-- <button class="controls-button" (click)="changeParticipantDevices()"><fa-icon [icon]="faCog"></fa-icon></button> -->
      </ng-container>

  </div>



</div>



