<div class="container" *ngIf='showContainer'>
  <!-- <div *ngIf="errorMessage.length > 0" class="alert alert-danger" role="alert">
    {{errorMessage}}
  </div>
  <button mat-fab class="mb-2" (click)="startRecording()" color="primary" [disabled]='recording'>Iniciar</button>
  <button mat-fab class="mb-2" (click)="stopRecording()" color="primary" [disabled]='!recording'>Parar</button> -->
  <p class="subtitle"  cdkDrag>
    <ng-container *ngFor="let p of participants">
      <ng-container *ngIf="p.show">
        <span class="title" [style.color]="p.userInfo.color">{{p.userInfo.username}}</span>:
        <span class="text">{{p.message}}</span>
        <br/>
      </ng-container>
    </ng-container>
  </p>
</div>
