import { SocketService } from './../../../services/socket.service';
import { EventEmitter, Input, Output, ViewChild, ElementRef, HostListener, AfterContentInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FileUploadService } from '../../../services/file-upload.service';
import { create } from '../../../../../node_modules/simple-drawing-board/dist/simple-drawing-board.js';
import { OpentokService } from '../../../services/opentok.service';
import * as _ from 'lodash';
import { v4 as uuid } from 'uuid';



@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent implements OnInit, AfterContentInit {

  @Input() pizarraAbierta: Boolean;
  @Output() callParent = new EventEmitter();
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  sdb: create;

  colorSelected = '#000000';
  pencilSize = 1;
  file: any;
  textoBotonEnviar = 'upload_file';
  contadorImagen = 1;

  receivedImage = {currentBg: ''};
  drawAllowed = false;
  users = [];

  /**
   * Listener on screen resize for adapt the draw
   *
   * @param {*} event
   * @memberof BoardComponent
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const canvasElement = this.canvas.nativeElement; // Canvas reference
    // Save the current value of drawing, because on resize the canvas set to the default BG color
    const bg = this.sdb._history._present;

    // Set the canvas size
    this.calcularTamanoCanvas();

    // Set the previous status to new size
    this.setBackgroundImage(bg);
  }


  constructor(public uploadFile: FileUploadService,
    public ots: OpentokService,
    public socket: SocketService) {
  }

  ngOnInit(): void {

    // scale the canvas
    this.calcularTamanoCanvas();

    // Create the canvas for draw
    this.sdb = new create(this.canvas.nativeElement, {
      lineColor: this.colorSelected,
      lineSize: 1,
      boardColor: '#ffffff',
      historyDepth: 10
    });

    // Set permissions
    if (this.socket.userData.hasOwnProperty('role')) {
      this.drawAllowed = (this.socket.userData.role === 'doctor');
    }

    this.setListeners();

  }

  ngAfterContentInit(): void {
    // Called after ngOnInit when the component's or directive's content has been initialized.
    // Add 'implements AfterContentInit' to the class.

    setTimeout(() => {
      this.calcularTamanoCanvas();
    }, 500);
  }


  setListeners() {
    // Emit Local draw listeners
    if (this.sdb) {
      this.sdb.observer.on('drawBegin', coords => {
        const canvas = this.canvas.nativeElement;
        const boardSize = {x: canvas.width, y: canvas.height };

        if (this.drawAllowed) {
          this.socket.drawBegin(coords, this.colorSelected, this.pencilSize, boardSize);
        }
      });

      this.sdb.observer.on('draw', coords => {
        const canvas = this.canvas.nativeElement;
        const boardSize = {x: canvas.width, y: canvas.height };

        if (this.drawAllowed) {
          this.socket.draw(coords, boardSize);
        }
      });

      this.sdb.observer.on('drawEnd', coords => {
        const canvas = this.canvas.nativeElement;
        const boardSize = {x: canvas.width, y: canvas.height };

        if (this.drawAllowed) {
          this.socket.drawEnd(coords, boardSize);
        }
      });

    }

    // Draw from remote listeners
    const socket = this.socket.socketRAW;
    const ctx = this.canvas.nativeElement.getContext('2d');

    socket.on('drawBegin', data => {
      if (data.EH.username !== this.socket.userData.username) {
        const newCoords = this.translateCoords(data.boardSize, data.coords);
        ctx.beginPath();
        ctx.strokeStyle = this.colorSelected;
        ctx.lineWidth = this.pencilSize;
        ctx.moveTo(newCoords.x, newCoords.y);
      }
    });

    socket.on('draw', data => {
      if (data.EH.username !== this.socket.userData.username) {
        const newCoords = this.translateCoords(data.boardSize, data.coords);
        ctx.lineTo(newCoords.x, newCoords.y);
        ctx.stroke();
      }
    });

    socket.on('drawEnd', data => {
      if (data.EH.username !== this.socket.userData.username) {
        const newCoords = this.translateCoords(data.boardSize, data.coords);
        ctx.lineTo(newCoords.x, newCoords.y);
        ctx.stroke();
        ctx.beginPath();

        // Save to history
        this.sdb._history.save(this.sdb.toDataURL());
      }
    });

    socket.on('actionBoard', data => {
      if (data.EH.username !== this.socket.userData.username) {
        this.opcionesPantalla(data.action, false);
      }
    });

    socket.on('imageBoard', data => {
      if (data.EH.username !== this.socket.userData.username) {
        // Check if is the last frame
        if (data.data.seq === 0) { this.receivedImage[data.data.id] = []; }
        this.receivedImage[data.data.id].push(data.data);

        if (data.data.end) {
          this.processLargeImage(data.data.id);
        }
      }
    });

    socket.on('changeColor', data => {
      if (data.EH.username !== this.socket.userData.username) {
        this.colorSelected = data.color;
        this.sdb.setLineColor(data.color);
      }
    });

    socket.on('changePencilSize', data => {
      if (data.EH.username !== this.socket.userData.username) {
        this.pencilSize = data.size;
        this.sdb.setLineSize(data.size);
      }
    });

    socket.on('givePencil', name => {
      if (this.socket.userData.role !== 'doctor') {
        this.drawAllowed = (this.socket.userData.username === name);
      }
    });

    socket.on('participants', users => {
      this.users = users;
    });

    // Event listener on draw for disabled
    this.canvas.nativeElement.addEventListener('mousedown', e => {
      const self =  this;
      if (this.canvas.nativeElement.classList.contains('disabled')) {
        self.sdb._onInputCancel();
      }
      e.preventDefault();
      e.stopPropagation();

      return false;
    });

    // Obtener lista de usuarios conectados al socket
    this.getParticipants();
  }

  establecerColor(color: string) {
    this.sdb.setLineColor(color);
    this.socket.socketRAW.emit('changeColor', color);
  }

  establecerLinea(tamano: number) {
    this.sdb.setLineSize(tamano);
    this.socket.socketRAW.emit('changePencilSize', tamano);
  }

  llenarPantalla(color: string) {
    this.sdb.fill(color);
  }

  calcularTamanoCanvas() {
    this.canvas.nativeElement.width = document.getElementById('canvasContainer').offsetWidth * 0.9;
    this.canvas.nativeElement.height = document.getElementById('canvasContainer').offsetHeight * 0.9;
  }

  getParticipants() {
     // Listen for users
     this.socket.socketRAW.emit('getParticipants');
  }

  setPencil(event) {
    this.socket.socketRAW.emit('givePencil', event.value);
  }

  opcionesPantalla(evento: string, socket = true) {

    switch (evento) {
      case 'clear':
        this.sdb.clear();
        this.sdb._history.clear();
        break;
      case 'undo':
        this.sdb.undo();
        break;
      case 'redo':
        this.sdb.redo();
        break;
      default:
        break;
    }

    // Conditional to check if we need to send the action to the socket;
    if (socket) {
      this.socket.socketRAW.emit('actionBoard', evento);
    }
  }

  guardarDibujo() {

    // Open locally
    // const url = this.sdb.toDataURL();
    // const w = window.open('');
    // const imageOut = new Image();
    // imageOut.src = url;
    // w.document.write(imageOut.outerHTML);
    // console.log(url);
    // w.document.close();


    const image = this.dataURLtoFile(this.sdb._history.value, `imagen${this.contadorImagen}.png`);

    // Modificamos la variable del listener para añadir el loader al botón
    this.textoBotonEnviar = 'autorenew';
    // Llamamos al servicio para realizar la petición post
    const estado = this.uploadFile.sendFile(image, this.ots.sessionData.Session.Hash, '1');

    // Nos suscribimos al servicio y esperamos a la respuesta (interior de la función)
    estado.subscribe(response => {

      // Procesamos la respuesta obtenida del servidor
      this.processResponse(response);

    });
  }

  processResponse(response) {

    if (response.resuelto !== undefined && response.resuelto === 'OK') {

      this.contadorImagen++;
      this.textoBotonEnviar = 'upload_file';
    } else if (response.resuelto !== undefined && response.resuelto === 'ER') {
      console.log('Error al enviar el mensaje');
    } else {
      console.log('NOKNER');
    }
  }

  /**
   * Close the board and send the order troguh socket
   *
   * @memberof BoardComponent
   */
  cerrarPizarra() {
    this.pizarraAbierta = false;
    this.callParent.emit(this.pizarraAbierta);
  }

  dataURLtoFile(dataurl, filename) {

    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);

    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {
      type: mime
    });
  }


  /**
   * Handle the upload of local file
   *
   * @param {object} fileInput - Event with file added
   * @returns
   * @memberof BoardComponent
   */
  fileChangeEvent(fileInput) {
    // Check that exists the upload file
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Set the size of screen
      this.calcularTamanoCanvas();

      // Size Filter Bytes
      const max_size = 10 * 1000000;
      const allowed_types = ['image/png', 'image/jpeg'];

      // Check size of image
      if (fileInput.target.files[0].size > max_size) {
          alert(`Maximum size allowed is ${max_size / 1000000} Mb`);

          return false;
      }

      // Check for allowed types
      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
          alert('Only Images are allowed ( JPG | PNG )');
          return false;
      }

      // Open the image
      const reader = new FileReader();
      reader.onload = (e: any) => {
          this.receivedImage.currentBg = e.target.result;
          this.setBackgroundImage(e.target.result);

          // Send via socket
          const enc = new TextEncoder();
          const imgArrayBuffer = enc.encode(e.target.result);
          this.sendLargeImage(imgArrayBuffer);

      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }

  }

  /**
   * Send through socket a large image splitting it
   *
   * @param {Uint8Array} array - The base64 image encoded to Uint8Array
   * @param {number} [count=100000] - The size of each block
   * @memberof BoardComponent
   */
  sendLargeImage(array, count = 100000) {
    let n = 0; // Indicator of first element to take from array
    let seq = 0; // Integer sequence of each block for reassemble in target
    const id = uuid(); // Aun UUIDv4 for identify the correct image

    // Slice the array until ends
    while (n < array.length) {
      // Get the part indicated
      const part = array.slice(n, n + count);

      // Create JSON block with data and metadata of the slice.
      const json = {
        seq,
        part: [...part],
        id,
        end: false
      };

      // Sum sequence and another block
      n += count;
      seq += 1;

      // When last part, set the end boolean to true
      if (n > array.length) {
        json.end = true;
      }

      // Send the JSON data package through socket
      this.socket.socketRAW.emit('imageBoard', json);

    }
  }

  /**
   * Get a storage image received via socket and join all the parts
   *
   * @param {string} id - The id of the image to reassemble
   * @memberof BoardComponent
   */
  processLargeImage(id) {
    // Set an empty array for joint all parts
    let arrayBuffer = [];

    // Sort all the parts and add the number values to the array
    this.receivedImage[id].sort((a, b) => a.seq - b.seq ).forEach(e => {
     arrayBuffer = arrayBuffer.concat(e.part);
    });

    // Convert to uint8array and decode to base64
    const uint8Buffer = new Uint8Array(arrayBuffer);
    const decoder = new TextDecoder();
    const imgB64 = decoder.decode(uint8Buffer);

    // Delete the array of data
    delete this.receivedImage[id];

    // Set the image as background in board
    this.setBackgroundImage(imgB64);

    // Reset canvas size
    this.calcularTamanoCanvas();
  }

  /**
   * Set a image as background in the canvas
   *
   * @param {String} b64 - Base64 encoded image
   * @memberof BoardComponent
   */
  setBackgroundImage(b64) {
    // Define the canvas size as the maximum allowed
    const canvas = this.canvas.nativeElement;
    const MAX_WIDTH = canvas.width;
    const MAX_HEIGHT = canvas.height;

    // Generate a image element and set the base64 as src
    const image = new Image();
    image.src = b64;

    // Get the correct aspect ratio for the image
    image.onload = rs => {
      let height = rs.currentTarget['height'];
      let width = rs.currentTarget['width'];

      // Add the resizing logic
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }

        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }

      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      // Specify the resizing result
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0, width, height);
      this.establecerColor(this.colorSelected);
      this.establecerLinea(this.pencilSize);

      // Save to history
      this.sdb._history.save(this.sdb.toDataURL());
    };
  }

  /**
   * Transform the origin coordinates adapting it to your own screen size
   *
   * @param {*} drawerSize - Object with the original drawer canvas resolution
   * @param {*} coords - The original coords of draw
   * @returns {Object} The translate coords to your board
   * @memberof BoardComponent
   */
  translateCoords(drawerSize, coords) {
    const canvas = this.canvas.nativeElement;
    const newCoords = {
      x: coords.x / drawerSize.x * canvas.width,
      y: coords.y / drawerSize.y * canvas.height
    };
    return newCoords;
  }

}
