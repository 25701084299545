<div *ngIf="session">



  <!-- Módulo de subtitulos -->
  <div class="formdiv">
    <mat-form-field>
      <mat-label>Idioma transcripción</mat-label>
      <mat-select [(ngModel)]="languageSelected">
        <mat-option *ngFor="let lan of languages" [value]="lan.code">{{lan.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <app-subtitles [active]="subtitles" [languageCode]="languageSelected"></app-subtitles>

 <!--  <div class="landscape-overlay" *ngIf="ots.userConfig.portraitMsg">
    <span>{{ots.userConfig.portraitMsg}}</span>
  </div> -->
  <div *ngIf="permissions['CreateStreams']" [ngClass]="{smallVideo: board || shareScreen || screen}">
    <app-publisher *ngIf="doPublish" [role]="ots.userData.role" [session]="session" [publishingScreen]="shareScreen"  (smvideoChange)="handleChangeSV($event)"></app-publisher>
  </div>

  <div id="screen-{{ots.userData.role}}" *ngIf="currentState != 'disconnected' && currentState != 'disconnecting'; else disconnected">
    <div id="videos" *ngIf="!board && !shareScreen && !screen; else smallVideoTemp">
      <div id="textos-inicio">
        <h1>Bienvenido {{ots.userData.name}} </h1>
        <div *ngIf="permissions['ManageArchives']" style="display: none;">
          <span>You can Manage Archives</span>
          <br>
          <button (click)="startArchive()">Start recording</button>
          <button (click)="stopArchive()">Stop recording</button>
        </div>

        <h2 *ngIf="streams.length == 0">{{ots.userConfig.emptyMsg||'No hay nadie más en la llamada'}}</h2>
        <h2 *ngIf="spectactorsMessage.length > 0">{{spectactorsMessage}} está espectando la llamada</h2>
      </div>


        <app-subscriber *ngFor="let stream of streams" [className]="'role_'+stream.role" [ngClass]="{'user': screen && stream.strm.videoType === 'camera', 'screen': screen && stream.strm.videoType === 'screen'}" [stream]="stream.strm" [session]="session"></app-subscriber>
    </div>

    </div>

  <!-- Template desconexión -->
  <ng-template #disconnected>
    <div id="textos-inicio">
      <h2>Ha abandonado la llamada</h2>
    </div>
  </ng-template>

  <!-- Button full screen -->
  <button mat-fab [ngClass]="{'fullscreen-btn-active': fullscreenOn}" class="fullscreen-btn" (click)="toggleFullscreen()" >
    <span class="material-icons" *ngIf="fullscreenOn"> fullscreen</span>
    <span class="material-icons" *ngIf="!fullscreenOn"> fullscreen_exit</span>
  </button>

</div >
<!-- End of session active -->
<!-- <button (click)="board = !board" style="position: absolute; top: 0px; left: 400px; z-index: 888888;">Pissarra</button> -->
<div *ngIf="!session">
  <h1>Loading...</h1>
</div>

<br><br><br>

<!-- Pizarra abierta -->
<ng-template #smallVideoTemp>
  <div *ngIf="board" #boardOpen>
    <div class="flex">
      <div class="flex-left">
        <app-board [pizarraAbierta]="board" (callParent)="cerrarPizarra($event)"></app-board>
      </div>
      <div class="flex-right">
        <app-subscriber *ngFor="let stream of streams"  [ngClass]="{'user': screen && stream.strm.videoType === 'camera', 'screen': screen && stream.strm.videoType === 'screen'}" [stream]="stream.strm" [session]="session" mode="smallVideo"></app-subscriber>
      </div>
    </div>
  </div>

  <!-- Compartiendo pantalla -->
  <div *ngIf="shareScreen" #screenSharing>
    <div class="flex">
      <div class="flex-left">
        <app-screen-sharing [(sharing)]="shareScreen"></app-screen-sharing>
      </div>
      <div class="flex-right">
        <app-subscriber *ngFor="let stream of streams"  [ngClass]="{'user': screen && stream.strm.videoType === 'camera', 'screen': screen && stream.strm.videoType === 'screen'}" [stream]="stream.strm" [session]="session" mode="smallVideo"></app-subscriber>
      </div>
    </div>
  </div>

  <div *ngIf="screen" #someoneSharing>
    <div class="flex">
      <div class="flex-left">
        <ng-container *ngFor="let stream of streams">
          <app-subscriber *ngIf="stream.strm.videoType === 'screen'"   [ngClass]="{'user': screen && stream.strm.videoType === 'camera'}" [stream]="stream.strm" [session]="session"></app-subscriber>
        </ng-container>
      </div>
      <div class="flex-right">
        <ng-container *ngFor="let stream of streams">
          <app-subscriber *ngIf="stream.strm.videoType !== 'screen'"   [ngClass]="{'user': screen && stream.strm.videoType === 'camera'}" [stream]="stream.strm" [session]="session" mode="smallVideo"></app-subscriber>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
